
export const GridContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

export const ImageIcons = {
  width: '20%',
  marginBottom: '10%'
}