import styled from "styled-components";

export const Wrapper = styled.div `
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media only screen and (max-width: 600px) {
    height: auto;
  }
  
`

export const ScrollWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: end;
  svg {
    color: lightgray;
    width: 14px;
    cursor: pointer;
    margin: 5px;
  }
`