import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { LogoContainer } from './style';

function DrawerAppBar(props) {
  return (
      <AppBar component="nav"  sx={{ background: 'transparent', boxShadow: 'none'}}>
        <Toolbar>
          <a href='/'>
            <LogoContainer src='static/img/logo.png' alt="logo mapoteca"/> 
          </a>
        </Toolbar>
      </AppBar>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;