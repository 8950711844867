import { useEffect, useRef } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Title, Video, WrapperText } from '../../styles'
import AnimationLinear from '../../components/Animations/Linear';

const LANGUAGE = process.env.REACT_APP_LANGUAGE;

const PageSection = (props) => {
  const { translated } = props;
  const refVideo = useRef(null);

  useEffect(() => {
    refVideo.current.loop = true;
    refVideo.current.autoplay = true;
  }, []);

  return (
    <div>
      <Container>
        <Grid container style={{ margin: "10% 0px"}}>
          <Grid item md={6} xs={12}>
          <Title> 
            <AnimationLinear text="M-Market" />
          </Title>
          </Grid>
          <Grid item md={6} xs={12}>
            <WrapperText size="30px" line="40px" >
              {/* <AnimationLinear text = */}
                  { translated["Con M-Market podrás hacer geomarketing real, accediendo a las tecnologías punta de lanza en el mercado. Conocer el comportamiento offline y online de tus clientes y potenciales clientes."] }
              {/* /> */}
            </WrapperText>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Video playsinline={true} autoplay={true} loop={true} muted={true} ref={refVideo}>
          <source src={`/static/videos/${LANGUAGE}/mmarket.mp4`} type="video/mp4" />
        </Video>
      </Container>
    </div>
  )
}

export default PageSection;