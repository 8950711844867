import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { Title, Space, WrapperText } from '../../styles';
import AnimationLinear from '../../components/Animations/Linear';
import { useForm, ValidationError } from '@formspree/react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const styleContainer = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: '15%'
}

const FORM_ID = process.env.REACT_APP_FORM;

const SuccessResult = (props) => {
  const { translated } = props;

  return (
    <Container>
      <Grid container style={styleContainer}>
        <Title> 
          <AnimationLinear text={translated["Contáctanos"]} />
        </Title>
        <CheckCircleOutlineIcon sx={{ fontSize: 100, marginTop: '10%' }} />
        <WrapperText>{translated["Enviado con Éxito"]}</WrapperText>
      </Grid>
    </Container>
  )
}

const PageSection = (props) => {
  const { translated } = props;

  const [state, handleSubmit] = useForm(FORM_ID);

  if (state.succeeded) {
      return <SuccessResult translated={translated} />
  }

  return (
    <div>
      <Container>
        <Grid container style={styleContainer}>
          <Title> 
            <AnimationLinear text={translated["Contáctanos"]} />
          </Title>
          <Space h="40px" />
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Grid md={6} xs={12} style={{ margin: '0 auto' }}>
              <FormControl fullWidth >
                <TextField
                  id="name"
                  name="name"
                  required
                  label={translated["Nombre"]}
                  variant="standard"
                />
                <ValidationError 
                  prefix="Name" 
                  field="name"
                  errors={state.errors} />
              </FormControl>
            </Grid>
            <Space h="40px" />
            <Grid md={6} xs={12} style={{ margin: '0 auto' }}>
              <FormControl fullWidth>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  required
                  label={translated["Email"]}
                  variant="standard"
                />
                <ValidationError 
                  prefix="email" 
                  field="email"
                  errors={state.errors} />
              </FormControl>
            </Grid>
            <Space h="40px" />
            <Grid md={6} xs={12} style={{ margin: '0 auto' }}>
              <FormControl fullWidth>
                <TextField
                  id="message"
                  name="message"
                  required
                  label={translated["Mensaje"]}
                  multiline
                  rows={4}
                  variant="standard"
                />
                <ValidationError 
                  prefix="message" 
                  field="message"
                  errors={state.errors}
                />
              </FormControl>
            </Grid>
            <Space h="40px" />
            <Grid md={6} xs={12} style={{ margin: '0 auto' }}>
              <Button
                type="submit"
                disabled={state.submitting}
                color="inherit"
                variant="outlined"
                endIcon={<SendIcon />}
              >
                {translated["Enviar"]}
              </Button>

            </Grid>
          </form>
        </Grid>
      </Container>
    </div>
  )
}

export default PageSection;