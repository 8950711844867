import styled from "styled-components";

export const Title = styled.h1`
  font-size: ${(props) => props.size || '70px'};
  text-align: ${(props) => props.align || 'left'};
  font-weight: 400;
  margin: 10px 0px;
  @media only screen and (max-width: 600px) {
    font-size: ${(props) => props.sizexs || '1.88rem'};
  }
`

export const WrapperText = styled.p`
  text-align: ${(props) => props.align || 'left'};
  font-size: ${(props) => props.size || '20px'};
  font-weight: 400;
  line-height: ${(props) => props.line || '30px'};
  margin: 0px;
  padding: 0px!important;
  display: block;
  @media only screen and (max-width: 600px) {
    font-size: ${(props) => props.sizexs || '1rem'};
    line-height: ${(props) => props.linexs || '1.4rem'};
  }
`

export const Divider = styled.div `
  width: 100%;
  height: 1px;
  background-color: black;
  margin: ${(props) => props.margin || '70px 0px'};
  @media only screen and (max-width: 600px) {
    margin: ${(props) => props.marginxs || '30px 0px'};
  }
`

export const ButtonWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  button {
    font-size: 25px;
    @media only screen and (max-width: 600px) {
      font-size: 1rem;
      margin: 20px 0;
    }
  }
`

export const ImageListWrapper = styled.div `
  width: 60%;
  margin: 100px auto;
  li {
    margin-left: 20px;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: 10px auto!important;
    li {
      margin-left: 5px;
    }
  }
`

export const ImageItem = styled.img `
  width: 80%;
`

export const Video = styled.video `
  width: 100%;
`

export const Link = styled.a `
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: ${(props) => props.size || '20px'};
  line-height: ${(props) => props.line || '34px'};
  justify-content: ${(props) => props.align || 'left'};
  color: black;
  margin: 15px 0px;
  &:hover {
    text-decoration: underline black 1px;
  }
  @media only screen and (max-width: 600px) {
    font-size: ${(props) => props.sizexs || '1rem'};
    line-height: ${(props) => props.linexs || '1.4rem'};
  }
`

export const LinkRedirect = styled.div `
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: ${(props) => props.size || '20px'};
    line-height: ${(props) => props.line || '34px'};
    justify-content: ${(props) => props.align || 'left'};
    color: black;
    margin: 15px 0px;
    &:hover {
      text-decoration: underline black 1px;
    }
    @media only screen and (max-width: 600px) {
      font-size: ${(props) => props.sizexs || '1rem'};
      line-height: ${(props) => props.linexs || '1.4rem'};
    }
  }
`

export const Space = styled.div `
  width: 100%;
  height: ${ (props) => props.h || '10px' }
`