
import { useLittera } from "@assembless/react-littera";
import Container from '@mui/material/Container';
import { Divider } from '../styles'
import translations from '../translations';
import AboutSection from '../components/About/Page1';
import FooterSection from '../components/Footer';
import { useEffect } from "react";


const About = () => {
  const translated = useLittera(translations.about);
  const translatedFooter = useLittera(translations.footer);
  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <Container maxWidth="xl">
      <AboutSection translated={translated}/>
      <Divider />
      <FooterSection translated={translatedFooter} />
    </Container>
  )
}

export default About;

