
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AnimationLinear from '../../components/Animations/Linear';
import { Title, WrapperText, Divider } from '../../styles'

const AboutSection = (props) => {
  const { translated } = props;

  return (
    <div>
      <Container>
        <Grid container spacing={3} style={{ margin: "15% 0px"}} >
          <Title align="right">
            <AnimationLinear text={translated["Nacimos con el fin de cerrar la brecha de datos en Latino América."]} />
          </Title>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12} sx={{ display: 'flex', alignItems: 'center'}}>
            <WrapperText size="40px" line="50px" sizexs="1.4rem" linexs="2rem">
                <AnimationLinear text={translated["Sobre Mapoteca"]}  />
            </WrapperText>
          </Grid>
          <Grid item md={6} xs={12} sx={{ display: 'flex', flexDirection: 'column'}}>
            <WrapperText>
              {/* <AnimationLinear text= */}
              {translated["Mapoteca se define como una empresa productora de tecnologías de punta. Nacimos en el ańo 2018 en Chile. Desde ese momento no hemos parado de innovar y crecer, estando presentes hoy en toda la región latino americana, y avanzando a Europa y Estados Unidos."]} 
              {/* /> */}
            </WrapperText>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default AboutSection;