// import { useEffect, useRef } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Title, ButtonWrapper, ImageListWrapper, ImageItem, Divider, LinkRedirect } from '../../styles'
import Button from '@mui/material/Button';
import AnimationLinear from '../../components/Animations/Linear';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Slide from '../Slide';

const ImagesContainer = (props) => {
  const { itemData } = props;
  return (
    <ImageList cols={5} sx={{ alignItems: 'center'}}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <ImageItem
              src={`${item.img}?w=auto&h=150&fit=crop&auto=format`}
              srcSet={`${item.img}?w=auto&h=150&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
    </ImageList>
  )
}

const HomePageSection = (props) => {
  const { translated } = props;
  // const refVideo = useRef(null);

  // useEffect(() => {
  //   refVideo.current.loop = true;
  //   refVideo.current.autoplay = true;
  // }, []);

  return (
    <div>
      <Container>
        <Grid container style={{ margin: "7% 0px 15%"}}>
          <Grid item md={10} xs={12}>
          <Title> 
            <AnimationLinear text={translated.title} />
          </Title>
          </Grid>
          <Grid item md={2} xs={12}>
            <ButtonWrapper>
              <LinkRedirect>
                <LinkRouter to="/contact">
                  <Button
                    variant="outlined"
                    color="inherit">
                    {translated.comenzar}
                  </Button>
                </LinkRouter>
              </LinkRedirect>
            </ButtonWrapper>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <ImageListWrapper>
          <Slide 
            containers={[
                <ImagesContainer itemData={itemDataImage[0]}/>,
                <ImagesContainer itemData={itemDataImage[1]}/>
            ]}
            />
        </ImageListWrapper>
      </Container>
      {/* <Divider />
      <Container>
        <Video playsinline={true} autoplay={true} loop={true} muted={true} ref={refVideo}>
          <source src="/static/img/jumbo/es/mkt.mp4" type="video/mp4" />
        </Video>
      </Container> */}
    </div>
  )
}

export default HomePageSection;

const itemDataImage = [[
  {
    img: '/static/img/clients/gruporey.png',
    title: 'gruporey',
  },
  {
    img: '/static/img/clients/chilquinta.png',
    title: 'chilquinta',
  },
  {
    img: '/static/img/clients/mallplaza.png',
    title: 'mallplaza',
  },
  {
    img: '/static/img/clients/papajohns.png',
    title: 'papajohns',
  },
  {
    img: '/static/img/clients/equifax.png',
    title: 'equifax',
  }
], [
  {
    img: '/static/img/clients/oxxo.png',
    title: 'oxxo',
  },
  {
    img: '/static/img/clients/cchc.png',
    title: 'cchc',
  },
  {
    img: '/static/img/clients/comex.png',
    title: 'comex',
  },
  {
    img: '/static/img/clients/similares.png',
    title: 'similares',
  },
  {
    img: '/static/img/clients/favorita.png',
    title: 'favorita',
  }
]];