import { useRef, useEffect } from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AnimationLinear from '../../components/Animations/Linear';
import { Title, WrapperText, Video, Divider } from '../../styles'

const LANGUAGE = process.env.REACT_APP_LANGUAGE;

const HomePageSection = (props) => {
  const { translated } = props;
  const refVideo = useRef(null);

  useEffect(() => {
    refVideo.current.loop = true;
    refVideo.current.autoplay = true;
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
            <Video 
                style={{ height: 'auto', width: '100%'}}
                playsinline={true} 
                autoplay={true} 
                loop={true} 
                muted={true} 
                ref={refVideo}
            ><source src={`/static/videos/${LANGUAGE}/jumbo.mp4`} type="video/mp4" />
            </Video>
        </Grid>
        <Grid item md={6} xs={12} sx={{ display: 'flex', alignItems: 'center'}}>
          <WrapperText align="center" size="40px" line="50px">
            <AnimationLinear text={translated["Creamos soluciones basadas en nuestros geodatos y algoritmos, incrementando el alcance de tu negocio"]} />
          </WrapperText>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Title style={{ textAlign: 'center' }}> 
            <AnimationLinear text={translated["¿Qué es un motor geo-espacial?"]} />
          </Title>
        </Grid>
        <Grid item md={6} xs={12} sx={{ display: 'flex', alignItems: 'center'}}>
          <WrapperText align="center">
            {/* <AnimationLinear text= */}
            {translated["Un motor geo-espacial es una colección de tecnologías que permiten potenciar los datos primarios de clientes sumados a nuestros datos y algoritmos. Esto revela nuevas oportunidades de negocio, eficiencias en procesos y la generación de nuevos insights."]} 
          {/* /> */}
          </WrapperText>
        </Grid>
      </Grid>
    </Container>
        
  )
}

export default HomePageSection;