
import { useLittera } from "@assembless/react-littera";
import Container from '@mui/material/Container';
import { Divider } from '../styles'
import translations from '../translations';
import MMarket1 from '../components/MMarket/Page1';
import MMarket2 from '../components/MMarket/Page2';
import FooterSection from '../components/Footer';
import { useEffect } from "react";


const MMarket = () => {
  const translated = useLittera(translations.mmarket);
  const translatedFooter = useLittera(translations.footer);

  useEffect(() => {
    window.scrollTo(0,0)
  },[]);

  return (
    <Container maxWidth="xl">
      <MMarket1 translated={translated}/>
      <Divider />
      <MMarket2 translated={translated}/>
      <Divider />
      <FooterSection translated={translatedFooter} />
    </Container>
  )
}

export default MMarket;

