
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AnimationLinear from '../../components/Animations/Linear';
import { Title, WrapperText, Divider } from '../../styles'

const ValuesSection = (props) => {
  const { translated } = props;

  return (
    <div>
      <Container>
        <Grid container spacing={3} style={{ margin: "15% 0px"}} >
          <Title align="right">
            <AnimationLinear text={translated["Nuestros valores son la transparencia, la eficacia y la simpleza."]} />
          </Title>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12} sx={{ display: 'flex', alignItems: 'center'}}>
            <WrapperText size="40px" line="50px" sizexs="1.4rem" linexs="2rem">
                <AnimationLinear text={translated["Nuestros Valores"]} />
            </WrapperText>
          </Grid>
          <Grid item md={6} xs={12} sx={{ display: 'flex', flexDirection: 'column'}}>
            <WrapperText>
              {/* <AnimationLinear text= */}
              {translated["Creemos firmemente en un mundo en el cual las personas y las organizaciones, tienen derecho a su privacidad. Es por eso que no trabajamos con datos sensibles. Creemos que la eficacia en el desarrollo de soluciones es lo que requiere el mundo de hoy, siendo en ese sentido nuestras soluciones simples, al reducir problemas altamente complejos en clicks, llamados de API o reportes."]} 
              {/* /> */}
            </WrapperText>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ValuesSection;