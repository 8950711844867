import { Link as LinkRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AnimationLinear from '../../components/Animations/Linear';
import { Title, WrapperText, ButtonWrapper, Divider, LinkRedirect } from '../../styles'
import Profile from './Profile';


const TeamSection = (props) => {
  const { translated } = props;

  return (
    <div>
      <Container >
        <Grid container style={{ margin: "7% 0px 15%"}}>
          <Grid item md={12} xs={12}>
            <Title>
              <AnimationLinear text={translated["Equipo"]} />
            </Title>
          </Grid>
          <Grid item md={9} xs={12}>
            <br/>
            <WrapperText size="25px" line="45px">
              {/* <AnimationLinear text= */}
              {translated["Somos un equipo pequeño (y queremos serlo), altamente eficiente en lo que hace, bajo la filosofía que la empresa es también una tecnología. Por eso automatizar procesos y hacerlos simples, es lo que nos gusta. Mezclamos ciencia de datos espaciales, desarrollo de software, ventas y finanzas en un equipo de elite."]} 
              {/* /> */}
            </WrapperText>
          </Grid>
          <Grid item md={3} xs={12}>
            <ButtonWrapper>
              <LinkRedirect>
                <LinkRouter to="/contact">
                  <Button
                    variant="outlined"
                    color="inherit">
                    {translated.comenzar}
                  </Button>
                </LinkRouter>
              </LinkRedirect>
            </ButtonWrapper>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <Grid container spacing={3}>
          {
            data.map( (item, index) => (
              <Grid item md={3} xs={12}>
                <Profile 
                    key={index} {...item}
                />
              </Grid>
            ))
          }
        </Grid>
      </Container>
    </div>
  )
}

export default TeamSection;

const data = [
    {
      image: "/static/img/team/cris.jpg",
      linkedin: "https://www.linkedin.com/in/crishernandezco/",
      title: "Chief Executive Officer.",
      name: "Cris Hernández"
    },
    {
      image: "/static/img/team/federico.jpg",
      linkedin: "https://www.linkedin.com/in/federico-natho-370a12182/",
      title: "Data Scientist.",
      name: "Federico Natho"
    },
    {
      image: "/static/img/team/cris.jpg",
      linkedin: "https://www.linkedin.com/in/fernanda-nicole-figueroa-sandoval-60a9b9260/",
      title: "Finance Director.",
      name: "Fernanda Figueroa"
    },
    {
      image: "/static/img/team/cris.jpg",
      linkedin: "https://www.linkedin.com/in/francisca-escobar-herrera/",
      title: "Data Engineer.",
      name: "Francisca Escobar"
    },
    {
      image: "/static/img/team/cris.jpg",
      linkedin: "https://www.linkedin.com/in/marco-gutierrez-tolorza/",
      title: "Fullstack Engineer.",
      name: "Marco Gutierrez"
    },
    {
      image: "/static/img/team/cris.jpg",
      linkedin: "https://www.linkedin.com/in/matias-alvarez-correa/",
      title: "Fullstack Engineer.",
      name: "Matías Álvarez"
    },
    {
      image: "/static/img/team/cris.jpg",
      linkedin: "https://www.linkedin.com/in/misael-vega-geo/",
      title: "Sales Executive.",
      name: "Misael Vega"
    },
    {
      image: "/static/img/team/cris.jpg",
      linkedin: "https://www.linkedin.com/in/yeison-salinas/",
      title: "Chief Data Officer.",
      name: "Yeison Salinas"
    },
]