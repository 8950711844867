
import { useLittera } from "@assembless/react-littera";
import Container from '@mui/material/Container';
import { Divider } from '../styles'
import translations from '../translations';
import MMovil1 from '../components/MMovil/Page1';
import MMovil2 from '../components/MMovil/Page2';
import FooterSection from '../components/Footer';
import { useEffect } from "react";


const MMovil = () => {
  const translated = useLittera(translations.mmovil);
  const translatedFooter = useLittera(translations.footer);
  
  useEffect(() => {
    window.scrollTo(0,0)
  },[]);

  return (
    <Container maxWidth="xl">
      <MMovil1 translated={translated}/>
      <Divider />
      <MMovil2 translated={translated}/>
      <Divider />
      <FooterSection translated={translatedFooter} />
    </Container>
  )
}

export default MMovil;

