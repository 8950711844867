
import { useLittera } from "@assembless/react-littera";
import Container from '@mui/material/Container';
import { Divider } from '../styles'
import translations from '../translations';
import TeamSection from '../components/Team/Page1';
import FooterSection from '../components/Footer';
import { useEffect } from "react";


const Team = () => {
  const translated = useLittera(translations.team);
  const translatedFooter = useLittera(translations.footer);

  useEffect(() => {
    window.scrollTo(0,0)
  },[]);

  return (
    <Container maxWidth="xl">
      <TeamSection translated={translated}/>
      <Divider />
      <FooterSection translated={translatedFooter} />
    </Container>
  )
}

export default Team;

