
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AnimationLinear from '../../components/Animations/Linear';
import { Title, WrapperText, Divider } from '../../styles'


const ContainerCases = (props) => {
  const { title, description } = props;
  return (
    <div>
      <Divider />
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12} sx={{ display: 'flex', alignItems: 'center'}}>
            <WrapperText size="40px" line="50px" sizexs="1.4rem" linexs="2rem">
              <AnimationLinear text={`> ${title}`} />
            </WrapperText>
          </Grid>
          <Grid item md={6} xs={12} sx={{ display: 'flex', flexDirection: 'column'}}>
            <WrapperText>
              {/* <AnimationLinear text= */}
              {description} 
              {/* /> */}
            </WrapperText>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}


const PageSection = (props) => {
  const { translated } = props;

  return (
    <div>
      <Container>
        <Title> 
          <AnimationLinear text={translated["Casos de Éxito"]} />
        </Title>
      </Container>
      {
        data(translated).map((item, index) => (
          <ContainerCases key={index} {...item} />)
        )
      }
    </div>
  )
}

export default PageSection;

const data = (translated) => [
  {
    title: translated["Conveniencia"],
    description: translated["El operador de tiendas de conveniencia más grande de Latino América, utiliza diariamente nuestra tecnología para conocer mas profundamente sus audiencias actuales y proyectadas."]
  },
  {
    title: translated["Consumo Masivo"],
    description: translated["Uno de los players con más puntos de venta en Chile utiliza nuestra tecnología para poder crear modelos predictivos de ventas, eficientando sus recursos a aquellos con mejor performance."]
  },
  {
    title: translated["Servicios"],
    description: translated["Uno de nuestros más importantes clientes utiliza nuestra tecnología para mezclarla con sus datos de ventas internos, enrriqueciendo el valor de sus datos y análisis."]
  }
]