import { Link as LinkRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AnimationLinear from '../../components/Animations/Linear';
import { Title, WrapperText, Link, Divider, ButtonWrapper, LinkRedirect } from '../../styles'

const FooterSection = (props) => {
  const { translated } = props;

  return (
    <div>
      <Container>
        <Grid container>
            <Title> 
              <AnimationLinear text={translated["Desarrollemos juntos"]} />
            </Title>
          <Grid item md={9} xs={12}>
            <br/>
            <WrapperText size="30px" line="40px">
              <AnimationLinear text=
              {translated["Operamos en TODO Iberoamérica. Súmate y comienza a potenciar tu negocio."]} 
              />
            </WrapperText>
          </Grid>
          <Grid item md={3} xs={12}>
            <ButtonWrapper>
              <LinkRedirect>
                <LinkRouter to="/contact">
                  <Button
                    variant="outlined"
                    color="inherit">
                    {translated.comenzar}
                  </Button>
                </LinkRouter>
              </LinkRedirect>
            </ButtonWrapper>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={5}>
          <Grid item md={4} xs={12}>
            <Divider margin="70px 0px 20px" />
            <LinkRedirect>
              <LinkRouter to="/about">
                <span>{translated["Sobre Nosotros"]}</span>
              </LinkRouter>
            </LinkRedirect>
            <LinkRedirect>
              <LinkRouter to="/our-values">
                <span>{translated["Nuestros Valores"]}</span>
              </LinkRouter>
            </LinkRedirect>
            <LinkRedirect>
              <LinkRouter to="/team">
                <span>{translated["Equipo"]}</span>
              </LinkRouter>
            </LinkRedirect>
          </Grid>
          <Grid item md={4} xs={12}>
            <Divider margin="70px 0px 20px" />
              <LinkRedirect>
                <LinkRouter to="/cases-success">
                    <span>{translated["Casos de Éxito"]}</span>
                </LinkRouter>
              </LinkRedirect>
            <LinkRedirect>
              <LinkRouter to="/m-movil">
                <span>M-Movil</span>
              </LinkRouter>
            </LinkRedirect>
            <LinkRedirect>
              <LinkRouter to="/m-sales">
                <span>M-Sales</span>
              </LinkRouter>
            </LinkRedirect>
            <LinkRedirect>
              <LinkRouter to="/m-market">
                <span>M-Market</span>
              </LinkRouter>
            </LinkRedirect>
          </Grid>
          <Grid item md={4} xs={12}>
            <Divider margin="70px 0px 20px" />
            <Link target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/mapoteca">
              <span>LinkedIn</span>
            </Link>
            <Link target="_blank" rel="noreferrer" href="https://goo.gl/maps/atyyLft9GnVBCmL29">
              <span>Chile: San Sebastian 2909, Santiago, Region Metropolitana.</span>
            </Link>
            <Link target="_blank" rel="noreferrer" href="https://goo.gl/maps/kEewJko2Wz8BXVcB9">
              <span>Brasil: Rodovia PR 182, s/n, Km 320/321, Condomínio Industrial II, 3 Andar, Biopark, em Toledo - PR, CEP 85919-899.</span>
            </Link>
          </Grid>
        </Grid>
        <WrapperText size="14px" line="16px">
          © All rights reserved, Mapoteca, Chile
        </WrapperText>
      </Container>
    </div>
  )
}

export default FooterSection;