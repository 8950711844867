
import { useLittera } from "@assembless/react-littera";
import Container from '@mui/material/Container';
import { Divider } from '../styles'
import translations from '../translations';
import MSales1 from '../components/MSales/Page1';
import MSales2 from '../components/MSales/Page2';
import FooterSection from '../components/Footer';
import { useEffect } from "react";


const MSales = () => {
  const translated = useLittera(translations.msales);
  const translatedFooter = useLittera(translations.footer);

  useEffect(() => {
    window.scrollTo(0,0)
  },[]);

  return (
    <Container maxWidth="xl">
      <MSales1 translated={translated}/>
      <Divider />
      <MSales2 translated={translated}/>
      <Divider />
      <FooterSection translated={translatedFooter} />
    </Container>
  )
}

export default MSales;

