const translations = {
  home: {
    title: {
      es_ES: "Somos el motor geo-espacial que tu compañía necesita.",
      pt_PT: "Somos o motor geoespacial que sua empresa precisa.",
    },
    comenzar: {
      es_ES: "Comenzar",
      pt_PT: "Começar",
    },
    "Creamos soluciones basadas en nuestros geodatos y algoritmos, incrementando el alcance de tu negocio": {
      es_ES: "Creamos soluciones basadas en nuestros geodatos y algoritmos, incrementando el alcance de tu negocio",
      pt_PT: "Criamos soluções com base nos nossos geodados e algoritmos, aumentando o alcance do seu negócio"
    },
    "¿Qué es un motor geo-espacial?": {
      es_ES: "¿Qué es un motor geo-espacial?",
      pt_PT: "O que é um motor geoespacial?"
    },
    "Un motor geo-espacial es una colección de tecnologías que permiten potenciar los datos primarios de clientes sumados a nuestros datos y algoritmos. Esto revela nuevas oportunidades de negocio, eficiencias en procesos y la generación de nuevos insights.": {
      es_ES: "Un motor geo-espacial es una colección de tecnologías que permiten potenciar los datos primarios de clientes sumados a nuestros datos y algoritmos. Esto revela nuevas oportunidades de negocio, eficiencias en procesos y la generación de nuevos insights.",
      pt_PT: "Um mecanismo geoespacial é uma coleção de tecnologias que nos permitem aprimorar os dados primários do cliente adicionados aos nossos dados e algoritmos. Isso revela novas oportunidades de negócios, eficiências de processos e geração de novos insights."
    },
    "Plataformas": {
      es_ES: "Plataformas",
      pt_PT: "Plataformas"
    },
    "M-Movil es una solución creada colaborando con los players mas importantes del retail en Latino América y el Mundo. Provee una mirada completa a las audiencias que visitan tus tiendas y su comportamiento online y offline.": {
      es_ES: "M-Movil es una solución creada colaborando con los players mas importantes del retail en Latino América y el Mundo. Provee una mirada completa a las audiencias que visitan tus tiendas y su comportamiento online y offline.",
      pt_PT: "M-Movil é uma solução criada em colaboração com os mais importantes players de varejo da América Latina e do mundo. Forneça uma visão completa dos públicos que visitam suas lojas e seu comportamento online e offline."
    },
    "M-Sales es una solución creada junto con proveedores de pagos. Permite a comercios medianos y pequeńos tener una visión 360 de su mercado. El objetivo es visibilizar audiencias e incrementar las ventas.": {
      es_ES: "M-Sales es una solución creada junto con proveedores de pagos. Permite a comercios medianos y pequeńos tener una visión 360 de su mercado. El objetivo es visibilizar audiencias e incrementar las ventas.",
      pt_PT: "M-Sales é uma solução criada em conjunto com provedores de pagamento. Permite que médias e pequenas empresas tenham uma visão 360º do seu mercado. O objetivo é dar visibilidade ao público e aumentar as vendas."
    },
    "M-Market es una solución que permite, mezclando el mundo web con el físico, segmentar tu mercado y realizar acciones de marketing como nunca habías podido realizar. Enfocado a todos quienes quieran hacer geo-marketing real.": {
      es_ES: "M-Market es una solución que permite, mezclando el mundo web con el físico, segmentar tu mercado y realizar acciones de marketing como nunca habías podido realizar. Enfocado a todos quienes quieran hacer geo-marketing real.",
      pt_PT: "M-Market é uma solução que permite, misturando o mundo web com o físico, segmentar o seu mercado e realizar ações de marketing como nunca antes conseguiu. Focado em todos que querem fazer geo-marketing de verdade."
    },
    "Conocer más": {
      es_ES: "Conocer más",
      pt_PT: "Saiba mais"
    },
    "¿Qué nos hace tan requeridos?": {
      es_ES: "¿Qué nos hace tan requeridos?",
      pt_PT: "O que nos torna tão necessários?"
    },
    "Equipo": {
      es_ES: "Equipo",
      pt_PT: "Time"
    },
    "Co-creamos": {
      es_ES: "Co-creamos",
      pt_PT: "nós co-criamos"
    },
    "Nuestras soluciones estan creadas para ser consumidas desde el inicio como una API. Esto permite que puedas conectarte desde el software que ya usas, hasta montar una nueva solución sobre la nuestra.": {
      es_ES: "Nuestras soluciones estan creadas para ser consumidas desde el inicio como una API. Esto permite que puedas conectarte desde el software que ya usas, hasta montar una nueva solución sobre la nuestra.",
      pt_PT: "Nossas soluções são criadas para serem consumidas desde o início como uma API. Isso permite que você conecte desde o software que já utiliza até a montagem de uma nova solução em cima da nossa."
    },
    "Somos una mezcla de geógrafos, desarrolladores de software y expertos comerciales/financieros. Sabemos lo que hacemos y por qué lo hacemos. Somos ágiles y expertos.": {
      es_ES: "Somos una mezcla de geógrafos, desarrolladores de software y expertos comerciales/financieros. Sabemos lo que hacemos y por qué lo hacemos. Somos ágiles y expertos.",
      pt_PT: "Somos uma mistura de geógrafos, desenvolvedores de software e especialistas em negócios/financeiros. Sabemos o que fazemos e porque o fazemos. Somos ágeis e especialistas."
    },
    "Respetamos tu expertise en la industria y queremos aprender de ti, potenciandote con lo que nosotros sabemos hacer. Esto nos permite co-crear soluciones que realmente agregan valor.": {
      es_ES: "Respetamos tu expertise en la industria y queremos aprender de ti, potenciandote con lo que nosotros sabemos hacer. Esto nos permite co-crear soluciones que realmente agregan valor.",
      pt_PT: "Respeitamos sua experiência no setor e queremos aprender com você, capacitando-o com o que sabemos fazer. Isso nos permite cocriar soluções que realmente agregam valor."
    },
    "Ofrecemos servicios en 19 Países. Lo que contratas con nosotros sirve en donde tu tengas operaciones. Tienes los mismos resultados y la misma calidad sin demorar meses en implementar.": {
      es_ES: "Ofrecemos servicios en 19 Países. Lo que contratas con nosotros sirve en donde tu tengas operaciones. Tienes los mismos resultados y la misma calidad sin demorar meses en implementar.",
      pt_PT: "Oferecemos serviços em 19 países. O que você contrata conosco funciona onde você tem operações. Você tem os mesmos resultados e a mesma qualidade sem levar meses para implementar."
    }
  },
  footer: {
    "Desarrollemos juntos": {
      es_ES: "Desarrollemos juntos",
      pt_PT: "Vamos desenvolver juntos"
    },
    comenzar: {
      es_ES: "Comenzar",
      pt_PT: "Começar",
    },
    "Operamos en TODO Iberoamérica. Súmate y comienza a potenciar tu negocio.": {
      es_ES: "Operamos en TODO Iberoamérica. Súmate y comienza a potenciar tu negocio.",
      pt_PT: "Atuamos em TODA Iberoamérica. Junte-se a nós e comece a impulsionar o seu negócio."
    },
    "Sobre Nosotros": {
      es_ES: "Sobre Nosotros",
      pt_PT: "Sobre nós"
    },
    "Nuestros Valores": {
      es_ES: "Nuestros Valores",
      pt_PT: "Nossos valores"
    },
    "Equipo": {
      es_ES: "Equipo",
      pt_PT: "Time"
    },
    "Casos de Éxito": {
      es_ES: "Casos de Éxito",
      pt_PT: "Casos de sucesso"
    }
  },
  about: {
    "Sobre Mapoteca": {
      es_ES: "Sobre Mapoteca",
      pt_PT: "Sobre a biblioteca de mapas"
    },
    "Mapoteca se define como una empresa productora de tecnologías de punta. Nacimos en el ańo 2018 en Chile. Desde ese momento no hemos parado de innovar y crecer, estando presentes hoy en toda la región latino americana, y avanzando a Europa y Estados Unidos.": {
      es_ES: "Mapoteca se define como una empresa productora de tecnologías de punta. Nacimos en el ańo 2018 en Chile. Desde ese momento no hemos parado de innovar y crecer, estando presentes hoy en toda la región latino americana, y avanzando a Europa y Estados Unidos.",
      pt_PT: "A Mapoteca define-se como uma empresa produtora de tecnologias de ponta. Nascemos em 2018 no Chile. Desde então não paramos de inovar e crescer, estando hoje presentes em toda a região da América Latina, avançando para a Europa e Estados Unidos."
    },
    "Nacimos con el fin de cerrar la brecha de datos en Latino América.": {
      es_ES: "Nacimos con el fin de cerrar la brecha de datos en Latino América.",
      pt_PT: "Nascemos para fechar a lacuna de dados na América Latina."
    }
  },
  values: {
    "Nuestros valores son la transparencia, la eficacia y la simpleza.": {
      es_ES: "Nuestros valores son la transparencia, la eficacia y la simpleza.",
      pt_PT: "Nossos valores são transparência, eficiência e simplicidade."
    },
    "Nuestros Valores": {
      es_ES: "Nuestros Valores",
      pt_PT: "Nossos valores"
    },
    "Creemos firmemente en un mundo en el cual las personas y las organizaciones, tienen derecho a su privacidad. Es por eso que no trabajamos con datos sensibles. Creemos que la eficacia en el desarrollo de soluciones es lo que requiere el mundo de hoy, siendo en ese sentido nuestras soluciones simples, al reducir problemas altamente complejos en clicks, llamados de API o reportes.": {
      es_ES: "Creemos firmemente en un mundo en el cual las personas y las organizaciones, tienen derecho a su privacidad. Es por eso que no trabajamos con datos sensibles. Creemos que la eficacia en el desarrollo de soluciones es lo que requiere el mundo de hoy, siendo en ese sentido nuestras soluciones simples, al reducir problemas altamente complejos en clicks, llamados de API o reportes.",
      pt_PT: "Acreditamos firmemente em um mundo em que pessoas e organizações tenham direito à sua privacidade. É por isso que não trabalhamos com dados sensíveis. Acreditamos que eficiência no desenvolvimento de soluções é o que o mundo atual exige, nesse sentido nossas soluções são simples, reduzindo problemas de alta complexidade em cliques, chamadas de API ou relatórios."
    }
  },
  team: {
    "Equipo": {
      es_ES: "Equipo",
      pt_PT: "Time"
    },
    "Somos un equipo pequeño (y queremos serlo), altamente eficiente en lo que hace, bajo la filosofía que la empresa es también una tecnología. Por eso automatizar procesos y hacerlos simples, es lo que nos gusta. Mezclamos ciencia de datos espaciales, desarrollo de software, ventas y finanzas en un equipo de elite.": {
      es_ES: "Somos un equipo pequeño (y queremos serlo), altamente eficiente en lo que hace, bajo la filosofía que la empresa es también una tecnología. Por eso automatizar procesos y hacerlos simples, es lo que nos gusta. Mezclamos ciencia de datos espaciales, desarrollo de software, ventas y finanzas en un equipo de elite.",
      pt_PT: "Somos uma equipa pequena (e queremos ser), altamente eficiente no que faz, sob a filosofia de que a empresa é também uma tecnologia. É por isso que automatizar processos e torná-los simples é o que gostamos. Combinamos ciência de dados espaciais, desenvolvimento de software, vendas e finanças em uma equipe de elite."
    },
    comenzar: {
      es_ES: "Comenzar",
      pt_PT: "Começar",
    },
  },
  casesSuccess: {
    "Casos de Éxito": {
        es_ES: "Casos de Éxito",
        pt_PT: "Casos de sucesso"
    },
    "Conveniencia": {
      es_ES: "Conveniencia",
      pt_PT: "Conveniência"
    },
    "El operador de tiendas de conveniencia más grande de Latino América, utiliza diariamente nuestra tecnología para conocer mas profundamente sus audiencias actuales y proyectadas.": {
      es_ES: "El operador de tiendas de conveniencia más grande de Latino América, utiliza diariamente nuestra tecnología para conocer mas profundamente sus audiencias actuales y proyectadas.",
      pt_PT: "A maior operadora de lojas de conveniência da América Latina utiliza nossa tecnologia diariamente para entender melhor seu público atual e projetado."
    },
    "Consumo Masivo": {
      es_ES: "Consumo Masivo",
      pt_PT: "Consumo massivo"
    },
    "Uno de los players con más puntos de venta en Chile utiliza nuestra tecnología para poder crear modelos predictivos de ventas, eficientando sus recursos a aquellos con mejor performance.": {
      es_ES: "Uno de los players con más puntos de venta en Chile utiliza nuestra tecnología para poder crear modelos predictivos de ventas, eficientando sus recursos a aquellos con mejor performance.",
      pt_PT: "Um dos players com mais pontos de venda no Chile utiliza nossa tecnologia para criar modelos preditivos de vendas, tornando seus recursos mais eficientes para quem tem o melhor desempenho."
    },
    "Servicios": {
      es_ES: "Servicios",
      pt_PT: "Serviços"
    },
    "Uno de nuestros más importantes clientes utiliza nuestra tecnología para mezclarla con sus datos de ventas internos, enrriqueciendo el valor de sus datos y análisis.": {
      es_ES: "Uno de nuestros más importantes clientes utiliza nuestra tecnología para mezclarla con sus datos de ventas internos, enrriqueciendo el valor de sus datos y análisis.",
      pt_PT: "Um de nossos clientes mais importantes usa nossa tecnologia para combiná-la com seus dados internos de vendas, enriquecendo o valor de seus dados e análises."
    }
  },
  mmovil: {
    "Tu decisión de localización en base a datos actualizados y que permiten anticipar en cualquier coordenada geográfica o dirección de calle, cuál será tu audiencia.": {
      es_ES: "Tu decisión de localización en base a datos actualizados y que permiten anticipar en cualquier coordenada geográfica o dirección de calle, cuál será tu audiencia.",
      pt_PT: "A sua decisão de localização com base em dados atualizados que lhe permitem antecipar em qualquer coordenada geográfica ou morada, qual será o seu público."
    },
    "La nueva generación de tecnología para la selección de sitios y optimización de localizaciones.": {
      es_ES: "La nueva generación de tecnología para la selección de sitios y optimización de localizaciones.",
      pt_PT: "A próxima geração de tecnologia para seleção de local e otimização de localização."
    },
    "Homologamos la data de cada País en grillas de 100 metros. Esto permite que las decisiones que tomes tengan el mismo nivel de detalle en todos tus mercados.": {
      es_ES: "Homologamos la data de cada País en grillas de 100 metros. Esto permite que las decisiones que tomes tengan el mismo nivel de detalle en todos tus mercados.",
      pt_PT: "Padronizamos os dados de cada país em grades de 100 metros. Isso permite que as decisões que você toma tenham o mesmo nível de detalhamento em todos os seus mercados."
    },
    "Agregamos millones de data points diarios de movilidad urbana con lo cual podrás conocer tu mercado hiperlocal real, no estimaciones en base a datos estáticos.": {
      es_ES: "Agregamos millones de data points diarios de movilidad urbana con lo cual podrás conocer tu mercado hiperlocal real, no estimaciones en base a datos estáticos.",
      pt_PT: "Adicionamos milhões de pontos de dados diários de mobilidade urbana com os quais você poderá conhecer seu mercado hiperlocal real, não estimativas baseadas em dados estáticos."
    },
    "Calculamos y aproximamos aquellos indicadores que no encuentras en fuentes gubernamentales. Somos capaces de reducir la escala de análisis.": {
      es_ES: "Calculamos y aproximamos aquellos indicadores que no encuentras en fuentes gubernamentales. Somos capaces de reducir la escala de análisis.",
      pt_PT: "Calculamos e aproximamos os indicadores que você não encontra nas fontes governamentais. Somos capazes de reduzir a escala de análise."
    }
  },
  msales: {
    "Nuestra tecnología desarrollada en conjunto con los proveedores de medios de pago, permite que puedas conocer insights de qué producto vender y además a quién debieses vendérselo.": {
      es_ES: "Nuestra tecnología desarrollada en conjunto con los proveedores de medios de pago, permite que puedas conocer insights de qué producto vender y además a quién debieses vendérselo.",
      pt_PT: "Nossa tecnologia, desenvolvida em conjunto com provedores de meios de pagamento, permite que você tenha insights sobre qual produto vender e também para quem você deve vendê-lo."
    },
    "Tecnología única para anticipar ventas y conocer las preferencias de tus clientes.": {
      es_ES: "Tecnología única para anticipar ventas y conocer las preferencias de tus clientes.",
      pt_PT: "Tecnologia exclusiva para antecipar vendas e conhecer as preferências de seus clientes."
    },
    "Obtén insights desde tu dispositivo de recepción de pagos. Con estos datos podrás tener una mirada profunda del comportamiento de tus clients en tienda.": {
      es_ES: "Obtén insights desde tu dispositivo de recepción de pagos. Con estos datos podrás tener una mirada profunda del comportamiento de tus clients en tienda.",
      pt_PT: "Obtenha informações do seu dispositivo de recebimento de pagamentos. Com esses dados, você pode ter uma visão profunda do comportamento de seus clientes na loja."
    },
    "Los patrones de movilidad, podrás entender de donde vienen tus clientes y aquellos que son potenciales clientes, conociendo conversión y otras variables de segmentación.": {
      es_ES: "Los patrones de movilidad, podrás entender de donde vienen tus clientes y aquellos que son potenciales clientes, conociendo conversión y otras variables de segmentación.",
      pt_PT: "Padrões de mobilidade, você conseguirá entender de onde vêm seus clientes e quem são clientes em potencial, conhecendo a conversão e outras variáveis ​​de segmentação."
    },
    "Estos dos conjuntos de datos te permitirán crear clusters de clientes, hacer dinámico tu set de productos y entender el comportamiento de tus clientes.": {
      es_ES: "Estos dos conjuntos de datos te permitirán crear clusters de clientes, hacer dinámico tu set de productos y entender el comportamiento de tus clientes.",
      pt_PT: "Esses dois conjuntos de dados permitirão que você crie clusters de clientes, dinamize seu conjunto de produtos e entenda o comportamento de seus clientes."
    }
  },
  mmarket: {
    "Con M-Market podrás hacer geomarketing real, accediendo a las tecnologías punta de lanza en el mercado. Conocer el comportamiento offline y online de tus clientes y potenciales clientes.": {
      es_ES: "Con M-Market podrás hacer geomarketing real, accediendo a las tecnologías punta de lanza en el mercado. Conocer el comportamiento offline y online de tus clientes y potenciales clientes.",
      pt_PT: "Com o M-Market você poderá fazer geomarketing de verdade, acessando as últimas tecnologias do mercado. Conheça o comportamento offline e online dos seus clientes e potenciais clientes."
    },
    "El canal de datos que te permitirá entender el comportamiento real de tus clientes y potenciales clientes.": {
      es_ES: "El canal de datos que te permitirá entender el comportamiento real de tus clientes y potenciales clientes.",
      pt_PT: "Tecnologia exclusiva para antecipar vendas e conhecer as preferências de seus clientes."
    },
    "Todos los datos de audiencia de tu tienda, centro comercial, concierto, o cualquier punto de interés.": {
      es_ES: "Todos los datos de audiencia de tu tienda, centro comercial, concierto, o cualquier punto de interés.",
      pt_PT: "Obtenha informações do seu dispositivo de recebimento de pagamentos. Com esses dados, você pode ter uma visão profunda do comportamento de seus clientes na loja."
    },
    "Conoce todo lo que puedes conocer de tus audiencias en físico. Su demografía, movilidad, exposición a otros puntos de interés.": {
      es_ES: "Conoce todo lo que puedes conocer de tus audiencias en físico. Su demografía, movilidad, exposición a otros puntos de interés.",
      pt_PT: "Saiba tudo o que você pode saber sobre seu público físico. Sua demografia, mobilidade, exposição a outros pontos de interesse."
    },
    "Mezcla las fuentes de datos anteriores con el comportamiento en línea de tus aduciencias. ¿Les gusta alguna bebida en específico, viajar?": {
      es_ES: "Mezcla las fuentes de datos anteriores con el comportamiento en línea de tus aduciencias. ¿Les gusta alguna bebida en específico, viajar?",
      pt_PT: "Misture as fontes de dados acima com o comportamento online de seu público. Você gosta de uma bebida específica, viajar?"
    }
  },
  contact: {
    "Contáctanos": {
      es_ES: "Contáctanos",
      pt_PT: "Contate-Nos"
    },
    "Nombre": {
      es_ES: "Nombre",
      pt_PT: "Nome"
    },
    "Email": {
      es_ES: "Email",
      pt_PT: "Email"
    },
    "Mensaje": {
      es_ES: "Mensaje",
      pt_PT: "Mensagem"
    },
    'Enviar': {
      es_ES: "Enviar",
      pt_PT: "Mandar"
    },
    "Enviado con Éxito": {
      es_ES: "Enviado con Éxito",
      pt_PT: "Enviado com sucesso"
    } 
  },
  example: {
    es_ES: "Example",
    pt_PT: "Przykład",
  },
};

export default translations;

