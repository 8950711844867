import { Link as LinkRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';
import AnimationLinear from '../../components/Animations/Linear';
import { Title, WrapperText, 
  // ImageItem, 
  Divider, ButtonWrapper, LinkRedirect } from '../../styles'

const HomePageSection = (props) => {
  const { translated } = props;

  return (
    <div>
      <Container>
        <Grid container>
          <Grid item md={9} xs={12}>
            <Title> 
              <AnimationLinear text={translated["¿Qué nos hace tan requeridos?"]} />
            </Title>
          </Grid>
          <Grid item md={3} xs={12}>
            <ButtonWrapper>
              <LinkRedirect>
                <LinkRouter to="/contact">
                  <Button
                    variant="outlined"
                    color="inherit">
                    {translated.comenzar}
                  </Button>
                </LinkRouter>
              </LinkRedirect>
            </ButtonWrapper>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <WrapperText size="40px" line="70px" sizexs="1.4rem" linexs="2rem">
              <AnimationLinear text="API"  />
            </WrapperText>
            <WrapperText>
              {/* <AnimationLinear text= */}
              {translated["Nuestras soluciones estan creadas para ser consumidas desde el inicio como una API. Esto permite que puedas conectarte desde el software que ya usas, hasta montar una nueva solución sobre la nuestra."]} 
              {/* /> */}
            </WrapperText>
          </Grid>
          <Grid item md={3} xs={12}>
            <WrapperText size="40px" line="70px" sizexs="1.4rem" linexs="2rem">
              <AnimationLinear text={translated["Equipo"]} />
            </WrapperText>
            <WrapperText>
              {/* <AnimationLinear text= */}
              {translated["Somos una mezcla de geógrafos, desarrolladores de software y expertos comerciales/financieros. Sabemos lo que hacemos y por qué lo hacemos. Somos ágiles y expertos."]}
               {/* /> */}
            </WrapperText>
          </Grid>
          <Grid item md={3} xs={12}>
            <WrapperText size="40px" line="70px" sizexs="1.4rem" linexs="2rem">
              <AnimationLinear text={translated["Co-creamos"]} />
            </WrapperText>
            <WrapperText>
              {/* <AnimationLinear text= */}
              {translated["Respetamos tu expertise en la industria y queremos aprender de ti, potenciandote con lo que nosotros sabemos hacer. Esto nos permite co-crear soluciones que realmente agregan valor."]} 
              {/* /> */}
            </WrapperText>
          </Grid>
          <Grid item md={3} xs={12}>
            <WrapperText size="40px" line="70px" sizexs="1.4rem" linexs="2rem">
              <AnimationLinear text="Escala" />
            </WrapperText>
            <WrapperText>
              {/* <AnimationLinear text= */}
              {translated["Ofrecemos servicios en 19 Países. Lo que contratas con nosotros sirve en donde tu tengas operaciones. Tienes los mismos resultados y la misma calidad sin demorar meses en implementar."]} 
              {/* /> */}
            </WrapperText>
          </Grid>
        </Grid>
      </Container>
      {/* <Box sx={{ bgcolor: '#f5f5f5', marginTop: '60px'}} > 
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <ImageItem
                  src={`/static/img/product/02.svg?w=auto&h=150&fit=crop&auto=format`}
                  srcSet={`/static/img/product/02.svg?w=auto&h=150&fit=crop&auto=format`}
                  alt='soluciones'
                  loading="lazy"
                />
          </Grid>
          <Grid item xs={4}>
          <ImageItem
              src={`/static/img/product/03.svg?w=auto&h=150&fit=crop&auto=format`}
              srcSet={`/static/img/product/03.svg?w=auto&h=150&fit=crop&auto=format`}
              alt='soluciones'
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4}>
            <ImageItem
              src={`/static/img/product/01.svg?w=auto&h=150&fit=crop&auto=format`}
              srcSet={`/static/img/product/01.svg?w=auto&h=150&fit=crop&auto=format`}
              alt='soluciones'
              loading="lazy"
            />
          </Grid>
        </Grid>
      </Box> */}
            
    </div>
  )
}

export default HomePageSection;