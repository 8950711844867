import { Route, Routes } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import NavBar from './components/NavBar'
import Home from './pages/Home'
import About from './pages/About'
import Team from './pages/Team'
import Values from './pages/Values'
import MMarket from './pages/MMarket'
import MMovil from './pages/MMovil'
import MSales from './pages/MSales'
import Contact from './pages/Contact'
import CasesSucces from './pages/CasesSucces'

const App = () => {
  return (
    <div>
      <NavBar />
      <Toolbar />
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/about' element={<About/>}></Route>
        <Route path='/team' element={<Team/>}></Route>
        <Route path='/our-values' element={<Values/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/m-market' element={<MMarket/>}></Route>
        <Route path='/m-sales' element={<MSales/>}></Route>
        <Route path='/m-movil' element={<MMovil/>}></Route>
        <Route path='/cases-success' element={<CasesSucces/>}></Route>
        <Route path='/*' element={<div>Not found</div>} />
      </Routes>
    </div>
  );
}

export default App;
