
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AnimationLinear from '../../components/Animations/Linear';
import { Title, WrapperText, Divider, LinkRedirect } from '../../styles'
import { Link as LinkRouter } from 'react-router-dom';

const ContainerItems = (props) => {
  const {
    title, 
    description, 
    href, 
    linkText 
  } = props;

  return (
    <div>
      <Divider />
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12} sx={{ display: 'flex', alignItems: 'center'}}>
            <WrapperText size="40px" line="50px" sizexs="1.4rem" linexs="2rem">
              <AnimationLinear text={`> ${title}`}  />
            </WrapperText>
          </Grid>
          <Grid item md={6} xs={12} sx={{ display: 'flex', flexDirection: 'column'}}>
            <WrapperText>
              {/* <AnimationLinear text= */}
              {description} 
              {/* /> */}
            </WrapperText>
            <LinkRedirect>
              <LinkRouter to={href} align="right">
                <span>{linkText} </span><ArrowForwardIcon />
              </LinkRouter>
            </LinkRedirect>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}


const HomePageSection = (props) => {
  const { translated } = props;

  return (
    <div>
      <Container>
        <Title> 
          <AnimationLinear text={translated["Plataformas"]} />
        </Title>
      </Container>

      {
        data(translated).map( (item, index) => (
          <ContainerItems key={index} {...item} />
        ))
      }
    </div>
  )
}

export default HomePageSection;

const data = (translated) => [
  {
    title: "M-Movil",
    description: translated["M-Movil es una solución creada colaborando con los players mas importantes del retail en Latino América y el Mundo. Provee una mirada completa a las audiencias que visitan tus tiendas y su comportamiento online y offline."],
    href: '/m-movil',
    linkText: translated["Conocer más"]
  },
  {
    title: "M-Sales",
    description: translated["M-Sales es una solución creada junto con proveedores de pagos. Permite a comercios medianos y pequeńos tener una visión 360 de su mercado. El objetivo es visibilizar audiencias e incrementar las ventas."],
    href: '/m-sales',
    linkText: translated["Conocer más"]
  },
  {
    title: "M-Market",
    description: translated["M-Market es una solución que permite, mezclando el mundo web con el físico, segmentar tu mercado y realizar acciones de marketing como nunca habías podido realizar. Enfocado a todos quienes quieran hacer geo-marketing real."],
    href: '/m-market',
    linkText: translated["Conocer más"]
  }
]