import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Title, ImageItem, WrapperText, Space } from '../../styles'
import AnimationLinear from '../../components/Animations/Linear';
import { ImageIcons, GridContainer } from './styles'



const ContainerItems = (props) => {
  const { image, description } = props;

  return (
    <Grid item md={4} xs={12} sx={GridContainer}>
      <ImageItem
        style={ImageIcons}
        src={`${image}?w=auto&h=150&fit=crop&auto=format`}
        srcSet={`${image}?w=auto&h=150&fit=crop&auto=format`}
        alt='soluciones'
        loading="lazy"
      />
      <WrapperText>
        { description }
      </WrapperText>
    </Grid>
  )
}

const PageSection = (props) => {
  const { translated } = props;

  return (
    <div>
      <Container>
        <Grid container style={{ margin: "15% 0px"}} spacing={2}>
          <Title> 
            <AnimationLinear text={
              translated["Tecnología única para anticipar ventas y conocer las preferencias de tus clientes."]
            } />
          </Title>
          <Space h="50px" />
          {
            data(translated).map( (item, index) => (
              <ContainerItems key={index} {...item} />
            ))
          }
        </Grid>
      </Container>
    </div>
  )
}

export default PageSection;

const data = (translated) => [
  {
    image: '/static/icons/calculator.png',
    description: translated["Obtén insights desde tu dispositivo de recepción de pagos. Con estos datos podrás tener una mirada profunda del comportamiento de tus clients en tienda."]
  },
  {
    image: '/static/icons/maps_turn_back.png',
    description: translated["Los patrones de movilidad, podrás entender de donde vienen tus clientes y aquellos que son potenciales clientes, conociendo conversión y otras variables de segmentación."]
  },
  {
    image: '/static/icons/undo_circle.png',
    description: translated["Estos dos conjuntos de datos te permitirán crear clusters de clientes, hacer dinámico tu set de productos y entender el comportamiento de tus clientes."]
  }
]