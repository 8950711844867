
import { useLittera } from "@assembless/react-littera";
import Container from '@mui/material/Container';
import { Divider } from '../styles'
import translations from '../translations';
import HomePage1 from '../components/Home/Page1';
import HomePage2 from '../components/Home/Page2';
import HomePage3 from '../components/Home/Page3';
import HomePage4 from '../components/Home/Page4';
import FooterSection from '../components/Footer';
import { useEffect } from "react";


const Home = () => {
  const translated = useLittera(translations.home);
  const translatedFooter = useLittera(translations.footer);
  
  useEffect(() => {
    window.scrollTo(0,0)
  },[]);

  return (
    <Container maxWidth="xl">
      <HomePage1 translated={translated}/>
      <Divider />
      <HomePage2 translated={translated} />
      <Divider />
      <HomePage3 translated={translated} />
      <Divider />
      <HomePage4 translated={translated} />
      <Divider />
      <FooterSection translated={translatedFooter} />
    </Container>
  )
}

export default Home;

