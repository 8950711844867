import AnimationLinear from '../../components/Animations/Linear';
import { WrapperText, ImageItem } from '../../styles'

const Profile = (props) => {
  const {
    title,
    linkedin,
    name
  } = props;
  return (
    <div>
      {/* <ImageItem
          style={{ width: "100px", borderRadius: '50%'}}
          src={`${image}?w=auto&h=150&fit=crop&auto=format`}
          srcSet={`${image}?w=auto&h=150&fit=crop&auto=format`}
          alt='soluciones'
          loading="lazy"
        /> */}
      <WrapperText style={{ margin: "10px 0px"}} size="25px" line="30px" sizexs="1.4rem" linexs="2rem">
          <AnimationLinear text={name} />
      </WrapperText>
      <WrapperText style={{ margin: "10px 0px"}}>
        {title}
      </WrapperText>
      <a href={linkedin} target="_blank" rel="noreferrer" style={{ margin: "10px 0px"}}>
        <ImageItem
            style={{ width: '30px'}}
            src={`/static/img/linkedin.png?w=auto&h=150&fit=crop&auto=format`}
            srcSet={`/static/img/linkedin.png?w=auto&h=150&fit=crop&auto=format`}
            alt='soluciones'
            loading="lazy"
          />
      </a>
    </div>
  )
}

export default Profile;