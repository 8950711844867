
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useState } from 'react';
import {  Wrapper, ScrollWrapper } from './styles';

const Slide = (props) => {
  const {
    containers
  } = props;
  const [slide, setSlide] = useState(0);
  
  return (
    <Wrapper>
       {
        containers[slide]
        }
      <ScrollWrapper>
        {containers.map((_, index) => (
          index === slide ?
          <CircleIcon key={index} onClick={() => setSlide(index)} /> :
          <RadioButtonUncheckedIcon key={index} onClick={() => setSlide(index)} />
        ))}
      </ScrollWrapper>
    </Wrapper>
  )
}

export default Slide;
