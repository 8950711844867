import AnimatedText from 'react-animated-text-content';

const AnimationLinear = (props) => {
  const {
    text
  } =  props;
  return (
    <AnimatedText
      type="chars"
      animation={{
        x: '-10px',
        scale: 1.1,
        ease: 'linear',
      }}
      interval={0.08}
      duration={0.9}
      includeWhiteSpaces
    >
      {text}
    </AnimatedText>
  )
}

export default AnimationLinear;